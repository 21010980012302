import APIRequest from './MainAPICall/api';
import config from '../APIConfig';

export const GetNav = () => {
    return APIRequest({
        url: config.baseURL + "/Navigation/GetNavigation",
        method: "get",
    });
}

export const GetInventoryNavigation = () => {
    return APIRequest({
        url: config.baseURL + "/Navigation/GetInventoryNavigation",
        method: "get",
    });
}

export const CheckURLNavAuth = (route) => {
    return APIRequest({
        url: config.baseURL + "/Navigation/CheckURLNavAuth",
        method: "post",
        body: { route: route } 
    });
}
