import React, { useEffect, useState } from 'react';
import { useHistory, Link, NavLink } from 'react-router-dom';
import { ListGroup, Button, Nav } from 'react-bootstrap';
import { CheckEditOpportunity, GetOpportunityList, GetOpportunityNavigation, CheckDrillingAuth } from '../API/Opportunity';
import { faSortAmountUp, faSortAmountDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default props => {
    const [opportunities, setOpportunities] = useState([]);
    const [filter, setFilter] = useState("");
    const [closedOpportunities, setClosedOpportunities] = useState(false);
    const [editOpportunity, setEditOpportunity] = useState(false);
    const [isDrilling, setIsDrilling] = useState(false);
    const [isDrillManager, setIsDrillManager] = useState(false);


    //Sorting values: 0 = default, 1 = decending, 2 = ascending 
    const [oppNameSort, setOppNameSort] = useState(0);
    const [descSort, setDescSort] = useState(0);
    const [accSort, setAccSort] = useState(0);
    const [classes, setClasses] = useState(["clickable", "clickable", "clickable"])


    useEffect(() => {
        checkDrillAuth()
    }, [])

    const checkDrillAuth = () => {
        CheckDrillingAuth()
            .then(res => {
                if (!res.success) {
                    setIsDrilling(false);
                }
                else if (res.success && res.message === 'Drilling Manager') {
                    setIsDrilling(true)
                    setIsDrillManager(true)
                }
                else if (res.success && res.message === 'Drilling User') {
                    setIsDrilling(true)
                    setIsDrillManager(false)
                }
            })
    }


    useEffect(() => {
        LoadOpportunities(closedOpportunities)
    }, [closedOpportunities])

    const [nav, setNav] = useState([]);
    useEffect(() => {
        GetOpportunityNavigation()
            .then(res => {
                setNav(res.data)
            })

        CheckEditOpportunity()
            .then(res => {
                setEditOpportunity(res.success)
            })
    }, [])

    const LoadOpportunities = (closed) => {
        GetOpportunityList(closed)
            .then(res => {
                setOpportunities(res.data)

            })
    }
    //const history = useHistory();
    //const navigateToOpportunity = (id) => {
    //    history.push(`/Opportunities/${id}`)
    //}

    const navigateToOpportunity = (id) => {
        const win = window.open(`/Opportunities/${id}`, '_blank');
        win.focus();

    }

    const setClass = (headerID) => {
        let newArr = [...classes]

        classes.forEach((c, index) => {
            if (headerID === index) {
                newArr[index] = "clickable fa-icon-toggle-on"
            }
            else {
                newArr[index] = "clickable"
            }
        })

        setClasses(newArr)

    }


    const headerSort = (headerID, direction) => {

        setClass(headerID)


        opportunities.forEach((opp) => {
            if (opp.opportunity.opportunityDescription === null || opp.opportunity.opportunityDescription === undefined) {
                opp.opportunity.opportunityDescription = '';
            }
        })


        if (headerID === 0 && direction === 1) {
            setOppNameSort(2)
            opportunities.sort((a, b) => a.opportunity.opportunityName.localeCompare(b.opportunity.opportunityName));
        }
        else if (headerID === 0 && direction === 2) {
            setOppNameSort(1)
            opportunities.sort((a, b) => a.opportunity.opportunityName.localeCompare(b.opportunity.opportunityName));
            opportunities.reverse();
        }
        //---------------------------------------------------------------------------
        if (headerID === 1 && direction === 1) {
            setDescSort(2)
            opportunities.sort((a, b) => a.opportunity.opportunityDescription.localeCompare(b.opportunity.opportunityDescription));
        }
        else if (headerID === 1 && direction === 2) {
            setDescSort(1)
            opportunities.sort((a, b) => a.opportunity.opportunityDescription.localeCompare(b.opportunity.opportunityDescription));
            opportunities.reverse();
        }
        //---------------------------------------------------------------------------
        if (headerID === 2 && direction === 1) {
            setAccSort(2)
            opportunities.sort((a, b) => a.account.accountName.localeCompare(b.account.accountName));
        }
        else if (headerID === 2 && direction === 2) {
            setAccSort(1)
            opportunities.sort((a, b) => a.account.accountName.localeCompare(b.account.accountName));
            opportunities.reverse();
        }
    }

    return (
        <>
            <Nav variant="tabs" className="my-2">
                {nav.map((m, i) => {
                    return (
                        <Nav.Item key={i}>
                            <Nav.Link exact={true} eventKey={i} as={NavLink} to={`${props.match.url}${m.url}`}>{m.label}</Nav.Link>
                        </Nav.Item>
                    )
                })}
            </Nav>
            <div className="container m-auto">
                <div className="row my-2">
                    {(!isDrilling || isDrillManager) ?
                        <div className="col-4">
                            {editOpportunity && <Button className="enc-button" as={Link} to={`/Opportunities/NewOpportunity`}>Create Opportunity</Button>}
                        </div>
                        : null}

                    <div className="col-4 text-right">
                        <div className="toggle-switch small-switch">
                            <input
                                type="checkbox"
                                className="toggle-switch-checkbox"
                                id="disabled"
                                checked={closedOpportunities}
                                onChange={() => setClosedOpportunities(!closedOpportunities)}
                            />
                            <label className="toggle-switch-label" htmlFor="disabled">
                                <span className={"toggle-switch-inner"} />
                                <span
                                    className={"toggle-switch-switch"}
                                />
                            </label>
                        </div>
                        &nbsp; &nbsp;Show Closed Opportunities
                    </div>
                    <div className="col-4 text-right">
                        <input placeholder="Account & Opportunity Name Filter" className="enc-input-100" value={filter} onChange={(e) => { setFilter(e.target.value) }} />
                    </div>

                </div>
                <div>
                    <ListGroup>
                        <ListGroup.Item className="enc-data-list">
                            Opportunity List
                        </ListGroup.Item>
                        <ListGroup.Item className="enc-data-list">
                            <div className="row">
                                <div className="col-lg-4">
                                    Opportunity Name {oppNameSort === 0 || oppNameSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[0]} onClick={() => headerSort(0, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className={classes[0]} onClick={() => headerSort(0, 2)} />}
                                </div>
                                <div className="col-lg-4">
                                    Description {descSort === 0 || descSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[1]} onClick={() => headerSort(1, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className={classes[1]} onClick={() => headerSort(1, 2)} />}
                                </div>
                                <div className="col-lg-4">
                                    Account Name {accSort === 0 || accSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[2]} onClick={() => headerSort(2, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className={classes[2]} onClick={() => headerSort(2, 2)} />}
                                </div>

                            </div>
                        </ListGroup.Item>
                        {opportunities.length === 0 && <ListGroup.Item> No Opportunities to Display </ListGroup.Item>}
                        {opportunities.filter(({ account, opportunity }) => opportunity.opportunityName.toLowerCase().search(filter.toLowerCase()) !== -1 || account.accountName.toLowerCase().search(filter.toLowerCase()) !== -1)
                            .map(({ opportunity, account }, m, i) =>

                                <ListGroup.Item key={`LGI-${opportunity.opportunityId}`} as={Link} to={`/Opportunities/${opportunity.opportunityId}`} className="click-list">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            {opportunity.opportunityName}
                                        </div>
                                        <div className="col-lg-4">
                                            {opportunity.opportunityDescription}
                                        </div>
                                        <div className="col-lg-4">
                                            {account.accountName}
                                        </div>
                                    </div>
                                </ListGroup.Item>)}
                    </ListGroup>
                </div>
            </div>
        </>
    )
}