import React, { useEffect, useState } from 'react';
import { Switch, Route, useLocation, useHistory  } from 'react-router-dom';

import Navigation from './Main/Navigation'
import Dashboard from './Dashboard/Dashboard';
import Profile from './User/profile';
import ChangePassword from './User/ChangePassword';
import AdminPanel from './Administrator/AdminPanel';
import CallMain from './CallIntake/CallMain';
import Accounts from './Accounts/Accounts';
import OpportunityRouter from './Opportunity/OpportunityRouter';
import EmployeeList from './Employees/EmployeeList';
import EditEmployee from './Employees/EditEmployee';

import JobList from './Jobs/JobList';
import TimeSheetsRouter from './TimeSheets/TimeSheetsRouter';
import InventoryRouter from './Inventory/InventoryRouter';

import Signature from './Components/Signature';
import SimplifiedCalculator from './Calculator/SimplifiedCalculator';
import UserSignatureUpload from './Components/UserSignatureUpload';

import Wiki from './Wiki/Search';
import { CheckURLNavAuth } from './API/Navigation'
import { GetRouteHelpLink } from './API/Wiki'

export default (props) => {

    const location = useLocation();
    const history = useHistory();

    const [helpLink, setHelpLink] = useState("");
    useEffect(() => {
        // grab route, check for help link, and render here. 
       // console.log(location.pathname);
        GetHelpLink(location.pathname);
    }, [location, history]);


    useEffect(() => {
        // grab route, check for help link, and render here. 
        CheckURLNavAuth(location.pathname)
            .then(res => {
                if (!res.success) {
                    history.push('/');
                }
            });
    }, [location, history]);


    const GetHelpLink = (route) => {
        GetRouteHelpLink(route)
            .then(res => {
                if (res.success) {
                    setHelpLink(res.data.url);
                }
                else {
                    setHelpLink("");
                }
            })
            .catch(
              err =>  console.log("Error getting helplink")
            );
    }

    return (
        <>
            <Navigation HelpLink={helpLink} />
            <div className="content-wrap">
                <Switch>
                    <Route path="/Calculator" render={()=><SimplifiedCalculator />} />
                    <Route path="/User/Profile" render={() =>
                        <Profile />
                    } />
                    <Route path="/User/ChangePassword" render={() =>
                        <ChangePassword />
                    } />
                    <Route path="/User/EditSignature" component={UserSignatureUpload} />

                    <Route path="/CallIntake" component={CallMain} />
                    <Route path="/Accounts" component={Accounts} />
                    <Route exact path={`/Employees/:EmployeeId`} render={(routeprops) =>
                        <EditEmployee employeeId={routeprops.match.params.EmployeeId} />
                    } />
                    <Route path="/Employees" component={EmployeeList} />
                    <Route path="/Timesheets" component={TimeSheetsRouter} />
                    <Route path="/Admin" render={() =>
                        <AdminPanel />
                    } />
                    <Route path="/Opportunities" component={OpportunityRouter} />
                    <Route path={`/Jobs/:type`} render={(routeprops) =>
                        <JobList type={routeprops.match.params.type} />
                    } />
                    <Route path={`/Jobs`} render={(routeprops) =>
                        <JobList url={routeprops.match.url} />
                    } />
                 
                    <Route path={`/Inventory`} component={InventoryRouter} />
                    <Route path={`/Signature`} component={Signature} />
                    <Route path={`/Wiki/Search`} component={Wiki} />
                    <Route path="/" render={(routeprops) =>
                        <Dashboard history={routeprops.history} />
                    } />
                </Switch>
            </div>
        </>
    )
}